import { setContext } from '@sentry/nextjs'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ToastContainer, Slide } from 'react-toastify'
import posthog from 'posthog-js'
import store from '@/store'
import { getAuthToken, getCookie, routeToLogin } from '@/services/utils'
import CommonComponent from '@/components/layouts/CommonLayout'
import ErrorBoundary from '@/components/ErrorBoundary'
import { COOKIE_KEYS } from '@/services/enums'
import config from '@/config'

import '@/styles/globals.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

const setCookies = () => {
  document.cookie = `token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RhdGEuZGV2LmJvb2tlZWFwcC5jb20vYXBpL2xvZ2luIiwiaWF0IjoxNjgxMTI3Mzg2LCJleHAiOjE3MTI2NjMzODYsIm5iZiI6MTY4MTEyNzM4NiwianRpIjoiZlZlVm1GTjFEOVNwUlJNbyIsInN1YiI6MywicHJ2IjoiYzhiZjExNGJkNzljZWY4NzliMGExMmRiYTQyMzdjMTZlOWQxZjA2MyIsImlkIjozLCJuYW1lIjoiVmlzdGFyIFMiLCJlbWFpbCI6InZpc3RhcnNpbmdoMStib3hpbmcxQGdtYWlsLmNvbSJ9.p7LAvMXmehT6avg5f2b5R2ehkAJ6orraUawj9EiHYXs; domain=localhost; path=/`
  document.cookie = `center_id=ff124e63-abc8-49a2-8c6d-ce8c421ec09d; domain=localhost; path=/`
  document.cookie = `timezone=Asia/Kolkata; domain=localhost; path=/`
  document.cookie = `country={"name":"United States","isd_code":"+1","iso_code_2":"US"}; domain=localhost; path=/`
  document.cookie = `user_name=Vistar; domain=localhost; path=/`
  document.cookie = `user_email=vistarsingh1+boxing1@gmail.com; domain=localhost; path=/`
  document.cookie = `user_business=F10 Boxing; domain=localhost; path=/`
  document.cookie = `business_id=f10boxing; domain=localhost; path=/`
}

export default function App({ Component, pageProps }) {
  const setSentryContext = () => {
    setContext('User', {
      name: getCookie(COOKIE_KEYS.userName),
      email: getCookie(COOKIE_KEYS.userEmail),
      businessName: getCookie(COOKIE_KEYS.userBusiness)
    })
  }

  const initPostHog = () => {
    posthog.init(config.POSTHOG_API_KEY, {
      api_host: 'https://app.posthog.com'
    })

    posthog.identify(getCookie(COOKIE_KEYS.userEmail), {
      name: getCookie(COOKIE_KEYS.userName),
      email: getCookie(COOKIE_KEYS.userEmail),
      org: getCookie(COOKIE_KEYS.userBusiness)
    })
    posthog.group('company', getCookie(COOKIE_KEYS.businessId), {
      company_name: getCookie(COOKIE_KEYS.userBusiness)
    })
  }

  useEffect(() => {
    //? uncomment below function to set cookies
    //! to be only used in dev mode (DO NOT COMMIT)
    // setCookies()

    if (!getAuthToken()) routeToLogin()

    setSentryContext()

    if (config.ENV_NAME === 'production') initPostHog()
  }, [])

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor="#eaecf0"
            highlightColor="#f9fafb"
            borderRadius={12}
          >
            <CommonComponent>
              <Component {...pageProps} />
              <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover
                newestOnTop
                pauseOnFocusLoss={false}
                theme="colored"
                transition={Slide}
              />
            </CommonComponent>
          </SkeletonTheme>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  )
}
