export default {
  BASE_URL: 'https://data.bookeeapp.com/',
  PUSHER_KEY: '661d4be379f71c3f0ef5',
  LOG_ROCKET: true,
  INTERCOM_APP_ID: 'gw2tyo2y',
  CHARGEBEE: 'bookeeapp',
  ADYEN_MODE: 'live',
  ENV_NAME: 'production',
  POSTHOG_API_KEY: 'phc_lY4TDHjq0AISfKcZbY1xqFKU4hFijQt0cPIpuvNF9qk',
  WEBSITE_URL: 'https://crm.bookeeapp.com/',
  AI_BASE_URL: 'https://anton.bookeeapp.com',
  WAF_URL:
    'https://22bfd41a62e0.us-east-1.sdk.awswaf.com/22bfd41a62e0/f9417369e628/challenge.js'
}
